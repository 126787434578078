import styled from 'styled-components'

export const PolicyArticle = styled.article`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: block;
`
